import { CustomCssVarsFn } from '@wix/yoshi-flow-editor';
import styleParam from '../BookingCalendar/stylesParams';
import { calendarWidgetSettingsParamsDefinitions } from './settingsParams';
import { settingsParamsDefinitions } from '../BookingCalendar/settingsParams';
import { generateCustomCssVars } from '../BookingCalendar/stylesParamsUtils';

export default styleParam;
export const customCssVars: CustomCssVarsFn = (args) =>
  generateCustomCssVars(args, {
    ...settingsParamsDefinitions,
    ...calendarWidgetSettingsParamsDefinitions,
  });
